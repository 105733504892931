import React from "react";
import stravaSvg from "./strava_button.svg";

export default function LoggedOutHome() {
  const insufficientScope = window.location.pathname === "/insufficient_scope";

  return (
    <div className="container vertical-center">
      <div className="row">
        <div className="col">
          <h1 className="display-4">Welcome to this unnamed app!</h1>
          <p className="lead">Get detailed analytics for your running data</p>
          <hr className="my-4" />

          {insufficientScope ? (
            <>
              <div className="card bg-warning">
                <div className="card-header">Uh oh!</div>
                <div className="card-body">
                  <p className="card-text">
                    It seems that you unchecked some of the permissions on the
                    last screen. Unfortunately, Strava doesn't allow us to fetch
                    the details of your activities unless all of those
                    permissions are granted. No information of yours was saved,
                    and you do not yet have an account here. Click the button
                    below to try again or contact
                    <a href="mailto:run@jbr.me">run@jbr.me</a> with any
                    privacy-related concerns.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <p>
                This application is still in early development and your feedback
                is essential.
              </p>
              <p className="text-danger">
                By logging into this app at this early stage of development, you
                consent to your data being used to improve the application.
              </p>
              <p>
                Eventually this will be an opt-in choice, but for now it's
                essential. Please contact{" "}
                <a href="mailto:run@jbr.me">run@jbr.me</a> with any concerns or
                questions.
              </p>
            </>
          )}
          <div className="text-center">
            <a
              href="/auth/strava"
              className="strava-auth btn button-lg"
              role="button"
            >
              <img alt="strava auth button" src={stravaSvg} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
