import React from "react";
import "./Spinner.scss";

export default function Spinner() {
  return (
    <div className="spinner vertical-center">
      <span>⛰</span>

      <svg
        className="spinner"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0" stopColor="gold" />
            <stop offset="100%" stopColor="red" />
          </linearGradient>
        </defs>
        <circle cx="50" cy="50" r="35" />
      </svg>
    </div>
  );
}
