import React from "react";
import { useUser } from "./UserLoader";
import { Link } from "rhoto-router";
import useTitle from "../hooks/useTitle";
import useSocket from "../hooks/useSocket";
import { User } from "../types/User";
import { ActivityListJSON, ActivityListEntryMap } from "./ActivityListEntry";
import EffortDensity from "./EffortDensity";
import ColorLegend from "./chart/ColorLegend";

interface NewUserJob {
  type: "newUser";
  userId: number;
}

interface GetActivityJob {
  type: "getActivity";
  userId: number;
  activityId: number;
  reprocess?: boolean;
  refetch?: boolean;
  analysis?: boolean;
  index?: number;
  total?: number;
  name?: string;
}

type MixedJob = NewUserJob | GetActivityJob;

interface Job {
  data: MixedJob;
  progress: number;
  id: number;
}

function messageForJob(job: Job): string {
  switch (job.data.type) {
    case "newUser":
      return "Fetching information about you from strava";
    case "getActivity":
      if (job.data.reprocess && job.data.analysis && job.data.name) {
        return `Analyzing "${job.data.name}"`;
      } else if (job.data.name) {
        return `Fetching "${job.data.name}"`;
      } else {
        return `Fetching data from strava`;
      }
  }
}

function DoneButton() {
  return (
    <div
      className="btn btn-primary btn-lg"
      onClick={() => window.location.reload()}
    >
      All Done! Click here to continue
    </div>
  );
}

function LastActivityPreview({ activity }: { activity: ActivityListJSON }) {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          {activity.race ? "🏅" : null} {activity.name}
        </h5>
        {activity.effortDensity ? (
          <div className="d-flex" style={{ width: "100%", height: 50 }}>
            <EffortDensity
              meanEffort={activity.meanEffort}
              effortDensity={activity.effortDensity}
            />
          </div>
        ) : null}
        <div className="d-flex" style={{ width: "100%", height: 300 }}>
          <ActivityListEntryMap entry={activity}>
            <ColorLegend top right background="rgba(255,255,255,0.5)" />
          </ActivityListEntryMap>
        </div>
      </div>
    </div>
  );
}

function ProgressIndicator({ queue }: { queue: Job[] }) {
  const jobToDisplay = queue[0];
  if (!jobToDisplay) return null;
  const s = jobToDisplay.data;
  const progress =
    "index" in s && typeof s.index === "number" && typeof s.total === "number"
      ? (100 * s.index) / s.total
      : jobToDisplay.progress;
  const progressMessage =
    "index" in s && typeof s.index === "number" && typeof s.total === "number"
      ? `${s.index}/${s.total}`
      : `${jobToDisplay.progress.toFixed()}%`;
  return (
    <>
      <p className="lead">
        We're processing your data right now. This will take a while.
      </p>
      <div>
        {messageForJob(jobToDisplay)}
        {progress ? (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${progress}%`
              }}
            >
              {progressMessage}
            </div>
          </div>
        ) : (
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: "100%" }}
              />
            </div>
          )}
      </div>
    </>
  );
}

export default function UnfetchedMessage() {
  const user = useUser();
  useTitle("We're still processing your data");
  const [currentState, setCurrentState] = React.useState<Job[]>([]);
  const [done, setDone] = React.useState<boolean>(false);
  const [
    lastActivity,
    setLastActivity
  ] = React.useState<ActivityListJSON | null>(null);
  useSocket(
    process.env.PUBLIC_URL,
    socket => {
      if (!currentState.length) socket.emit("status");
      socket.on("user", (user: User) => {
        if (user.processed && user.fetched) setDone(true);
      });

      socket.on("queue", (state: Job[]) => {
        setCurrentState(state);
      });
      socket.on("completed", (completed: ActivityListJSON) => {
        setLastActivity(completed);
      });
    },
    [setCurrentState, setDone]
  );

  return (
    <>
      <header className="analysis-nav">
        <nav className="navbar navbar-expand-sm fixed-top navbar-light bg-light">
          <Link className="navbar-brand" href="/" exact>
            <img
              alt={`${user.firstName} ${user.lastName}`}
              style={{ width: 30, borderRadius: 15 }}
              src={user.image}
              className="mx-3"
            />
            <span className="navbar-text">
              {user.firstName} {user.lastName}
            </span>
          </Link>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/auth/logout">Log out</a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="display-4">Welcome, {user.firstName}!</h1>
              {done ? (
                <DoneButton />
              ) : (
                  <ProgressIndicator queue={currentState} />
                )}
            </div>
            <hr className="my-4" />
          </div>
          <div className="row my-4">
            <div className="col-sm-4 d-flex">
              {lastActivity ? (
                <LastActivityPreview activity={lastActivity} />
              ) : null}
            </div>
            <div className="col-sm-8">
              <p>
                <span className="font-weight-bold">How this works:</span> As you
                read this, we're fetching your running history from strava and
                creating a model of your running performance in different
                conditions. We use this model to provide insights into your
                performance and effort for each new run you upload. We call this{" "}
                <span className="font-weight-bold">Personal Effort</span>
              </p>

              <p>
                Have you ever run up a steep hill and looked at your pace at the
                end and wondered how to understand whether your performance was
                at your intended effort level for that run? Or alternatively,
                have you ever been running a hilly race and noticed the same
                people passing you on the uphills who you then passed on the
                downhills? Especially for trail runners, we all have a vague
                sense of whether we're stronger at climbing or descending, and
                at what sorts of grades, but it's hard to know if we're
                improving over time.
              </p>

              <p>
                <span className="font-weight-bold">Personal Effort</span> is a
                performance indicator from 0 to 100 that is applied to every
                moment of a run, so you can see how your effort fluctuated or
                maintained over the course of your activity. Your personalized
                running model will also adapt over time, in order to reflect
                changes in your fitness and focus.
              </p>

              <p>
                In addition to{" "}
                <span className="font-weight-bold">Personal Effort</span>, we
                also provide a best-in-market analysis platform to help you
                understand your running performance and training load. As the
                app continues to evolve, we will incorporate all available data
                and provide longitundinal analysis so you can focus your
                training. In addition to understanding individual runs, we aim
                to provide a long-term data driven understanding of your running
                strengths and weaknesses.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
