import React from "react";
import { useRouter } from "rhoto-router";

export const apiFetch = async (path: string) => {
  const url = `/api${path}`;
  const result = await fetch(url);
  if (result.ok) {
    const resultBody = await result.text();
    return JSON.parse(resultBody);
  } else throw result;
};

type RequestState = "pending" | "new" | "complete" | "failed";

const cache = new Map<string, unknown>()

export function useAPIData<T>(route: string | null): T | null {
  const router = useRouter();
  const cachedData = process.env.NODE_ENV !== 'development' && route && cache.has(route) ? cache.get(route) as T : null
  const [requestState, setRequestState] = React.useState<RequestState>(cachedData ? "complete" : "new");
  const [data, setData] = React.useState<T | null>(cachedData || null);
  const mounted = React.useRef(true);

  React.useEffect(() => {
    if (requestState === "new" && route !== null) {
      setRequestState("pending");
      apiFetch(route)
        .then(d => {
          cache.set(route, d)
          if (mounted.current) {
            setRequestState("complete");
            setData(d);
          }
        })
        .catch(
          () =>
            mounted.current && setRequestState("failed") && router.notFound()
        );
    }
  }, [route, data, router, requestState]);

  React.useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  if (requestState === "complete") {
    return data;
  } else {
    return null;
  }
}
