import React from "react";
import DataContext from "./chart/DataContext";
import * as polyline from "@mapbox/polyline";
import Map from "./chart/Map";
interface MapPoint {
  wmpX: number;
  wmpY: number;
}
export default function PolylineThumbnail({ data }: { data: string }) {
  const points: MapPoint[] = React.useMemo(
    () =>
      polyline.decode(data).map(([lat, lng]) => ({
        wmpX: (lng + 180) / 360,
        wmpY:
          (1 -
            Math.log(
              Math.tan((Math.PI * lat) / 180) +
                1 / Math.cos((Math.PI * lat) / 180)
            ) /
              Math.PI) /
          2
      })),
    [data]
  );

  return (
    <DataContext.Provider value={points}>
      <Map<MapPoint>
        strokeWidth={3}
        tiles="osmNoLabels"
        wmpX="wmpX"
        wmpY="wmpY"
      />
    </DataContext.Provider>
  );
}
