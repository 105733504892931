import React from "react";

export default function useVisibility<T extends Element>(
  options?: IntersectionObserverInit,
  defaultVisibility?: boolean
): [React.RefObject<T>, boolean] {
  const ref = React.useRef<T>(null);
  const [visible, setVisibility] = React.useState(!!defaultVisibility);

  React.useEffect(() => {
    const observer = new window.IntersectionObserver(([entry]) => {
      setVisibility(entry.isIntersecting);
    }, options);

    const domNode = ref.current;

    if (domNode) observer.observe(domNode);

    return () => {
      if (domNode) observer.unobserve(domNode);
    };
  }, [options]);

  return [ref, visible];
}
