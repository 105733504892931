import _ from "lodash";
import { Stats } from "../common";

interface DimensionDefinition {
  displayLabel: string;
  units: string;
  labelValue(value: number): string;
  divisions: number[];
  stats?: Partial<Stats>;
}

function defaultDimension(dimensionName: string): DimensionDefinition {
  return {
    displayLabel: (dimensionName || "")
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .toLowerCase(),
    units: "",
    labelValue(value: number) {
      return Math.round(value).toString();
    },
    divisions: [0.5, 1, 2, 5, 10, 25, 50, 100, 250, 500, 1000, 5000]
  }
}

const allDimensions: { [index: string]: Partial<DimensionDefinition> } = {
  verticalSpeed: {
    labelValue(feetPerHour) {
      return `${Math.round(feetPerHour)}'/h`;
    },
    units: "feet/hour"
  },

  speedMph: {
    labelValue(value) {
      return `${value.toFixed(1)}mph`;
    },
    displayLabel: "speed",
    units: "mph"
  },

  smoothedVelocityMph: {
    labelValue(value) {
      return `${value.toFixed(1)}mph`;
    },
    displayLabel: "speed",
    units: "mph"
  },

  gradePerMi: {
    labelValue(value) {
      return `${Math.round(value)}'/mi`;
    },
    displayLabel: "grade",
    units: "feet per mile"
  },

  meanHeartrate: {
    labelValue(hr) {
      return `${Math.round(hr)}bpm`;
    },
    displayLabel: "heart rate",
    units: "bpm"
  },

  smoothedHeartrate: {
    labelValue(hr) {
      return `${Math.round(hr)}bpm`;
    },
    displayLabel: "heart rate",
    units: "bpm"
  },

  grade: {
    labelValue(value) {
      return `${Math.round(value * 100)}%`;
    },
    displayLabel: "gradient",
    units: "percent rise/run"
  },
  angle: {
    labelValue(angle) {
      return `${Math.round(angle)}º`;
    },
    units: "degrees"
  },

  paceMinMi: {
    labelValue(pace) {
      const minutes = Math.floor(pace);
      const seconds = Math.floor((pace - minutes) * 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}/mi`;
    },
    displayLabel: "pace",
    units: "min/mi"
  },

  meanCumulativeDistanceMi: {
    labelValue(distance) {
      return `${distance.toFixed(1)}mi`;
    },
    displayLabel: "distance",
    units: "miles"
  },

  distanceMi: {
    labelValue(distance) {
      return `${distance.toFixed(1)}mi`;
    },
    displayLabel: "distance",
    units: "miles"
  },

  timeMillis: {
    displayLabel: "time",
    units: "h:mm",
    labelValue(value) {
      const hours = Math.floor(value / (60 * 60 * 1000));
      const minutes = Math.floor(value / (60 * 1000) - hours * 60);
      return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    },
    divisions: [
      1000, //1s
      1000 * 60, //1min
      1000 * 60 * 5, //5min
      1000 * 60 * 15, //15min
      1000 * 60 * 30, //30min
      1000 * 60 * 60, //1h
      1000 * 60 * 60 * 2, //2h,
      1000 * 60 * 60 * 3, //3h,
      1000 * 60 * 60 * 4, //4h,
      1000 * 60 * 60 * 5 //5h,
    ]
  },

  elapsedTimeMillis: {
    displayLabel: "time",
    units: "h:mm",
    labelValue(value) {
      const hours = Math.floor(value / (60 * 60 * 1000));
      const minutes = Math.floor(value / (60 * 1000) - hours * 60);
      return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    },
    divisions: [
      1000, //1s
      1000 * 60, //1min
      1000 * 60 * 5, //5min
      1000 * 60 * 15, //15min
      1000 * 60 * 30, //30min
      1000 * 60 * 60, //1h
      1000 * 60 * 60 * 2, //2h,
      1000 * 60 * 60 * 3, //3h,
      1000 * 60 * 60 * 4, //4h,
      1000 * 60 * 60 * 5 //5h,
    ]
  },

  timeSeconds: {
    displayLabel: "time",
    units: "h:mm",
    labelValue(seconds) {
      const hours = Math.floor(seconds / (60 * 60));
      const minutes = Math.floor(seconds / 60 - hours * 60);
      return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    },
    divisions: [1, 60, 60 * 15, 60 * 30, 60 * 60, 60 * 5]
  },

  meanElevation: {
    displayLabel: "elevation",
    units: "feet",
    labelValue(elevation) {
      const numberFormat = new Intl.NumberFormat();
      return `${numberFormat.format(Math.floor(elevation))}'`;
    }
  },

  elevation: {
    displayLabel: "elevation",
    units: "feet",
    labelValue(elevation) {
      const numberFormat = new Intl.NumberFormat();
      return `${numberFormat.format(Math.floor(elevation))}'`;
    }
  },

  strideLengthFt: {
    displayLabel: "stride length",
    units: "feet",
    labelValue(strideLength) {
      return `${strideLength.toFixed(2)}'`;
    }
  },

  cadence: {
    units: "spm",
    labelValue(cadence) {
      return `${Math.floor(cadence * 2)}/min`;
    }
  },

  smoothedCadence: {
    units: "spm",
    displayLabel: "cadence",
    labelValue(cadence) {
      return `${Math.floor(cadence * 2)}/min`;
    }
  },

  smoothGradeAdjustedVelocity: {
    displayLabel: "relative speed",
    units: "mph",
    labelValue(sgav) {
      return sgav.toFixed(1);
    },
    divisions: [0.1, 0.25, 0.5, 1, 2, 5]
  },

  gradeSpeedPercentile: {
    displayLabel: "personal effort",
    units: "percent",
    labelValue(p) {
      return `${p.toFixed(0)}%`;
    },
    stats: { min: 0, max: 100, range: 100 }
  },
  smoothPercentile: {
    displayLabel: "personal effort",
    units: "percent",
    labelValue(p) {
      return `${p.toFixed(0)}%`;
    },
    stats: { min: 0, max: 100, range: 100 }
  }
};

export function getDimension<T>(
  dimensionName: Dimension<T> | Function
): DimensionDefinition {
  if (typeof dimensionName === 'string' && dimensionName in allDimensions) {
    return {
      ...defaultDimension(dimensionName as string),
      ...allDimensions[dimensionName as string]
    };
  } else {
    const name = typeof dimensionName === 'function' ? dimensionName.name : dimensionName as string
    return defaultDimension(name);
  }
}

export const dimensionsWithLabels = (...arr: string[]) =>
  _.flatten(arr).reduce(
    (o, k) => ({ ...o, [k]: getDimension(k).displayLabel }),
    {}
  );
const dimensions = [
  "meanCumulativeDistanceMi",
  "index",
  "points",
  "cadence",
  "distanceMi",
  "netElevationChange",
  "grossElevationChange",
  "elapsedTimeMillis",
  "timeMillis",
  "meanHeartrate",
  "meanElevation",
  "grade",
  "gradePerMi",
  "angle",
  "speedMph",
  "smoothedVelocityMph",
  "paceMinMi",
  "verticalSpeed",
  "gain",
  "loss",
  "gainPerMi",
  "lossPerMi",
  "smoothedHeartrate",
  "smoothedCadence",
  "gradeSpeedPercentile",
  "smoothPercentile"
] as const;

export const dimensionList = dimensionsWithLabels(...dimensions);

export type Dimension<T> = keyof T;

export const SUMMARY_STATS = [
  "mean",
  "min",
  "max",
  "variance",
  "stdev",
  "range",
  "median",
  "count",
  "sum"
] as const;

export type SummaryStat = typeof SUMMARY_STATS[number];

export const X_DIMENSIONS = dimensionsWithLabels(
  "meanCumulativeDistanceMi",
  "timeMillis"
);
