import React from "react";
import { useRouter } from "rhoto-router";
import DataContext from "./chart/DataContext";
import useTitle from "../hooks/useTitle";
import Spinner from "./Spinner";
import { useAPIData } from "../api";
import Point from "../point";

export interface ProcessedActivity {
  meta: StravaAPI.DetailedActivity;
  data: Point[];
  analysis: Analysis;
}

export interface Analysis {
  distance: number;
  meanEffort: number;
  totalEffort: number;
  effortDensity: number[];
}

export const StravaDataContext = ({
  stravaId,
  children
}: {
  stravaId: string;
  children: React.ReactNode;
}) => {
  const stravaData = useAPIData<ProcessedActivity>(`/activity/${stravaId}`);

  const title =
    (stravaData && stravaData.meta && stravaData.meta.name) || "Loading...";
  const race =
    stravaData && stravaData.meta && stravaData.meta.workout_type === 1;
  useTitle(`${race ? "🏅 " : ""}${title}`);

  if (stravaData) {
    return (
      <DataContext.Provider value={stravaData}>{children}</DataContext.Provider>
    );
  } else if (stravaData === null) {
    return <Spinner />;
  } else {
    return null;
  }
};

export const StravaLoader = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { stravaId } = router.params;

  if (stravaId) {
    return (
      <StravaDataContext stravaId={stravaId}>{children}</StravaDataContext>
    );
  } else {
    return null;
  }
};

export default StravaLoader;
