import React from "react";
import Spinner from "./Spinner";
import { useAPIData } from "../api";
import { Link } from "rhoto-router";
import { useUser } from "./UserLoader";

export default function UserList() {
  const user = useUser();
  const users = useAPIData("/users");
  if (users === null) {
    return <Spinner />;
  } else if (Array.isArray(users)) {
    return (
      <>
        <header>
          <nav className="navbar navbar-expand-sm fixed-top navbar-light bg-light">
            <Link className="navbar-brand" href="/" exact>
              {user && user.image && user.image.startsWith("http") ? (
                <img
                  alt={`${user.firstName} ${user.lastName}`}
                  style={{ width: 30, borderRadius: 15 }}
                  src={user.image}
                  className="mx-3"
                />
              ) : null}
              <span className="navbar-text">
                {user.firstName} {user.lastName}
              </span>
            </Link>

            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href="/auth/logout">Log out</a>
              </li>
            </ul>
          </nav>
        </header>

        <main className="user-list container">
          <div className="row">
            <div className="col">
              <ul className="list-group">
                {users.map(u => (
                  <React.Fragment key={u.id}>
                    <li className="list-group-item" key={u.id}>
                      <Link href={`/user/${u.stravaId}`}>
                        {u && u.image && u.image.startsWith("http") ? (
                          <img
                            alt={`${u.firstName} ${u.lastName} profile`}
                            src={u.image}
                            style={{ width: 30, borderRadius: 15 }}
                            className="mx-3"
                          />
                        ) : null}
                        {u.firstName} {u.lastName}
                      </Link>{" "}
                      <Link
                        className="float-right d-inline-block"
                        href={`/effort/${u.stravaId}`}
                      >
                        (effort)
                      </Link>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </main>
      </>
    );
  } else return null;
}
