import _ from "lodash";
import React from "react";
import ConvolutionalSmoother from "./chart/ConvolutionalSmoother";
import { CoordinateContextValue } from "./chart/CoordinateContext";
import Coordinates from "./chart/Coordinates";
import DataContext from "./chart/DataContext";
import FilledPath from "./chart/FilledPath";
import SizedSVG from "./chart/SizedSVG";
import { DEFAULT_COLOR_SCHEME } from "../data/colors";

interface EffortDensityDatum {
  density: number;
  effort: number;
}

/* function EffortIndicator({ effort }: { effort: number }) {
 *   const coordinates = React.useContext(CoordinateContext);
 *   return (
 *     <>
 *       <GradientBar
 *         x={0}
 *         y={-1}
 *         width={(coordinates.width * effort) / 100}
 *         height={coordinates.height}
 *         min={0}
 *         max={effort / 100}
 *         colorScale={d3.interpolatePlasma}
 *       />
 *       <text
 *         x={(coordinates.width * effort) / 200}
 *         y={coordinates.height / 2}
 *         textAnchor="middle"
 *         dominantBaseline="middle"
 *         fill="white"
 *       >
 *         {Math.floor(effort)}%
 *       </text>
 *     </>
 *   );
 * }
 *  */

export default function EffortDensity({
  meanEffort,
  effortDensity
}: {
  meanEffort?: number;
  effortDensity: number[];
}) {
  if (!effortDensity) {
    return (
      <div className="card card-body bg-warning">
        This activity is still processing
      </div>
    );
  }

  const effortDensityData: EffortDensityDatum[] = effortDensity.map(
    (density, effort) => ({
      density: density,
      effort: effort
    })
  );

  return (
    <DataContext.Provider value={effortDensityData}>
      <ConvolutionalSmoother<EffortDensityDatum> on="density" size={2}>
        <SizedSVG leftPad={0} rightPad={0} topPad={2} bottomPad={15}>
          <Coordinates
            x="effort"
            y="density"
            color="effort"
            colorScheme={DEFAULT_COLOR_SCHEME}
          >
            {({ xScale, height, width }: CoordinateContextValue) => (
              <>
                <FilledPath stroke="rgba(0,0,0,0.5)" strokeWidth="0.5" />

                {typeof meanEffort === "undefined" ||
                  (meanEffort / 100) * width > 30 ? (
                    <text
                      textAnchor="start"
                      x={xScale({ effort: 0 })}
                      y={height + 9}
                      fontSize="10"
                    >
                      0
                  </text>
                  ) : null}

                {typeof meanEffort === "undefined" ||
                  width - (width * meanEffort) / 100 > 40 ? (
                    <text
                      textAnchor="end"
                      x={xScale({ effort: 100 })}
                      y={height + 9}
                      fontSize="10"
                    >
                      100
                  </text>
                  ) : null}
                {typeof meanEffort !== "undefined" ? (
                  <>
                    <line
                      x1={xScale({ effort: meanEffort })}
                      x2={xScale({ effort: meanEffort })}
                      y1={height - 5}
                      y2={height + 1}
                      stroke="white"
                      strokeWidth="4"
                      strokeLinecap="round"
                    />

                    <line
                      x1={xScale({ effort: meanEffort })}
                      x2={xScale({ effort: meanEffort })}
                      y1={height - 5}
                      y2={height + 1}
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <text
                      textAnchor="middle"
                      x={_.clamp(
                        xScale({ effort: meanEffort }),
                        20,
                        width - 20
                      )}
                      y={height + 9}
                      fontSize="10"
                    >
                      mean: {Math.round(meanEffort)}
                    </text>
                  </>
                ) : null}
              </>
            )}
          </Coordinates>
        </SizedSVG>
      </ConvolutionalSmoother>
    </DataContext.Provider>
  );
}
