import React from "react";
import { User } from "../types/User";
import Spinner from "./Spinner";
import LoggedOutHome from "./LoggedOutHome";
import UnfetchedMessage from "./UnfetchedMessage";

type UserState = null | false | User;

interface UserLoaderProps {
  children: React.ReactNode;
}

export const UserContext = React.createContext<UserState>(null);
export const useUser = (): User => React.useContext(UserContext) as User;

export const UserLoader = ({ children }: UserLoaderProps) => {
  const [user, setUser] = React.useState<UserState>(null);
  React.useEffect(() => {
    if (user === null)
      fetch("/auth").then(response => {
        if (response.ok) {
          response
            .json()
            .then((user: User) => setUser(user))
            .catch(() => setUser(false));
        } else {
          setUser(false);
        }
      });
  }, [user]);

  if (user && user.processed) {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  } else if (user === false) {
    window.sessionStorage.setItem("redirect-back", window.location.href);
    return <LoggedOutHome />;
  } else if (user) {
    return (
      <UserContext.Provider value={user}>
        <UnfetchedMessage />
      </UserContext.Provider>
    );
  } else {
    return <Spinner />;
  }
};

export default UserLoader;
