import React from "react";
import { Route, Redirect, useRouter } from "rhoto-router";
import { DataFrame, useDataDimensions } from "./chart/DataContext";

const ConfigurableLineChart = React.lazy(() =>
  import("./ConfigurableLineChart")
);
const ConfigurableHeatmap = React.lazy(() => import("./ConfigurableHeatmap"));
const ConfigurableMap = React.lazy(() => import("./ConfigurableMap"));
const ConfigurableScatter = React.lazy(() => import("./ConfigurableScatter"));
const AnalysisNav = React.lazy(() => import("./AnalysisNav"));
const ConfigurableHistogram = React.lazy(() =>
  import("./ConfigurableHistogram")
);
const ConfigurableRibbonChart = React.lazy(() =>
  import("./ConfigurableRibbonChart")
);
const AnalysisSidebar = React.lazy(() => import("./AnalysisSidebar"));

function DefaultAnalysis() {
  const keys = useDataDimensions();
  const route = useRouter();
  if (keys.includes("wmpX") && keys.includes("wmpY")) {
    return <Redirect to={`${route.fullPath}/map`} />;
  } else {
    return <Redirect to={`${route.fullPath}/line`} />;
  }
}

export default function Analysis() {
  return (
    <>
      <AnalysisNav />
      <main role="main" className="container-fluid">
        <div className="row">
          <div className="col col-3 col-xl-2 d-none d-lg-flex sidebar">
            <AnalysisSidebar />
          </div>
          <div className="analysis-content col col-lg-9 col-xl-10 d-flex">
            <DataFrame part="data">
              <Route path="/line">
                <ConfigurableLineChart />
              </Route>
              <Route path="/heatmap">
                <ConfigurableHeatmap />
              </Route>

              <Route path="/scatter">
                <ConfigurableScatter />
              </Route>

              <Route path="/histogram">
                <ConfigurableHistogram />
              </Route>

              <Route path="/map">
                <ConfigurableMap />
              </Route>

              <Route path="/ribbon">
                <ConfigurableRibbonChart />
              </Route>
              <Route exact path="/">
                <DefaultAnalysis />
              </Route>
            </DataFrame>
          </div>
        </div>
      </main>
    </>
  );
}
