import React from "react";
import io from "socket.io-client";
export default function useSocket(
  url: string,
  fn: (socket: SocketIOClient.Socket) => void,
  changeTriggers: React.DependencyList
) {
  const callback = React.useCallback(fn, changeTriggers);
  const socket = React.useRef<SocketIOClient.Socket>();
  React.useEffect(() => {
    socket.current = io.connect(url, {
      transports: ["websocket", "polling"]
    });

    socket.current.on("connect", () => {
      if (socket.current) callback(socket.current);
    });

    const socketToDisconnect = socket.current;

    return () => {
      if (socketToDisconnect) socketToDisconnect.disconnect();
    };
  }, [callback, url]);
  return socket;
}
