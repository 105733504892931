import React from "react";

export default function useTitle(title?: string) {
  React.useEffect(() => {
    if (title) window.document.title = title;
  }, [title]);

  if (title) {
    return title;
  } else return window.document.title;
}
