import _ from "lodash";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "rhoto-router";
import { useAPIData } from "../api";
import { getDimension } from "../data/dimensions";
import useVisibility from "../hooks/useVisibility";
import EffortDensity from "./EffortDensity";
import PolylineThumbnail from "./PolylineThumbnail";
import DataContext from "./chart/DataContext";
import Map from "./chart/Map";

export interface ActivityListJSON {
  distanceMi: number;
  paceMinMi: number;
  movingTimeSeconds: number;
  elevationGainFt: number;
  id: number;
  startTime: number;
  meanEffort: number;
  effortSum: number;
  name: string;
  summaryMap: string;
  effortDensity: number[];
  race: boolean;
  perceivedExertion?: number;
  compressed?: [number, number, number][];
}

const FORMAT = {
  month: "long",
  day: "numeric",
  weekday: "short",
  hour: "numeric",
  minute: "2-digit"
};

const Stat = ({
  label,
  value,
  children
}: {
  label: string;
  value?: string;
  children?: React.ReactNode;
}) => (
    <div className="col-6 col-lg-3">
      <dt>{_.startCase(label)}</dt>
      <dd>
        {value}
        {children}
      </dd>
    </div>
  );

function PlaceholderActivityEntry() {
  const stats = ["distanceMi", "timeSeconds", "paceMinMi", "elevation"];

  return (
    <div className="card-body">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h5 className="card-title my-3 placeholder">Placeholder</h5>
            <div className="container">
              <p className="placeholder">placeholder</p>

              <div className="effort-density" />
              <hr />

              <div className="row">
                {stats.map(dimensionName => {
                  const dimension = getDimension(dimensionName);
                  return (
                    <Stat key={dimensionName} label={dimension.displayLabel}>
                      <span className="placeholder">placeholder</span>
                    </Stat>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="map placeholder" />
          </div>
        </div>
      </div>
    </div>
  );
}

interface CompressedPoint {
  wmpX: number;
  wmpY: number;
  smoothPercentile: number;
}

export function ActivityListEntryMap({
  entry,
  children
}: {
  entry: ActivityListJSON;
  children?: React.ReactNode;
}) {
  if (entry.compressed) {
    const data: CompressedPoint[] = entry.compressed.map(
      ([wmpX, wmpY, smoothPercentile]) => ({
        wmpX,
        wmpY,
        smoothPercentile
      })
    );

    return (
      <DataContext.Provider value={data}>
        <Map<CompressedPoint>
          strokeWidth={3}
          tiles="osmNoLabels"
          wmpX="wmpX"
          wmpY="wmpY"
          color="smoothPercentile"
          outline
          legend={false}
        >
          {children}
        </Map>
      </DataContext.Provider>
    );
  } else if (entry.summaryMap) {
    return <PolylineThumbnail data={entry.summaryMap} />;
  } else {
    return (
      <span
        className="treadmill-icon"
        role="img"
        aria-label="no map emoji, treadmill emoji"
      >
        🗺️🚫
      </span>
    );
  }
}

function VisibleActivityEntry({
  id,
  userId
}: {
  id: string | number;
  userId: string | number;
}) {
  const record = useAPIData<ActivityListJSON>(`/activities/${userId}/${id}`);

  if (record) {
    const start = DateTime.fromMillis(record.startTime);

    const stats = {
      distanceMi: record.distanceMi,
      timeSeconds: record.movingTimeSeconds,
      paceMinMi: record.paceMinMi,
      elevation: record.elevationGainFt
    };

    const startString = start.toLocaleString({
      ...FORMAT,
      year: start.year === new Date().getFullYear() ? undefined : "numeric"
    });

    return (
      <Link href={`/activity/${id}`}>
        {({ onClick }: { onClick: React.MouseEventHandler }) => (
          <div className="card-body" onClick={onClick}>
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <h5 className="card-title my-3">
                    <Link href={`/activity/${id}`}>
                      {record.race ? "🏅" : null} {record.name}
                    </Link>
                  </h5>
                  <div className="container">
                    {start ? <p>{startString}</p> : null}

                    <div className="effort-density">
                      <EffortDensity
                        meanEffort={record.meanEffort}
                        effortDensity={record.effortDensity}
                      />
                    </div>
                    <hr />

                    <div className="row">
                      {Object.entries(stats).map(([dimensionName, value]) => {
                        const dimension = getDimension(dimensionName);
                        if (value) {
                          return (
                            <Stat
                              key={dimensionName}
                              label={dimension.displayLabel}
                              value={dimension.labelValue(value)}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="map">
                    <ActivityListEntryMap entry={record} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Link>
    );
  } else return <PlaceholderActivityEntry />;
}

export default function ActivityEntry({
  id,
  userId
}: {
  id: string | number;
  userId: string | number;
}) {
  const [ref, visible] = useVisibility<HTMLDivElement>({ threshold: 0 });

  return (
    <div className="card activity-list-entry my-3 mx-1" key={id} ref={ref}>
      {visible ? (
        <VisibleActivityEntry id={id} userId={userId} />
      ) : (
          <PlaceholderActivityEntry />
        )}
    </div>
  );
}
