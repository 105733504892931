import React, { Suspense } from "react";
import Spinner from "./components/Spinner";
import { NotFound, Redirect, Route, Router, useRouter } from "rhoto-router";
import "./App.scss";
import Analysis from "./components/Analysis";
import StravaLoader from "./components/StravaLoader";
import UserLoader from "./components/UserLoader";
import UserList from "./components/UserList";

const ActivityList = React.lazy(() => import("./components/ActivityList"));
const EffortModel = React.lazy(() => import("./components/EffortModel"));

const RedirectFromSessionStorage = () => {
  const { navigate } = useRouter();
  const redirectBack = window.sessionStorage.getItem("redirect-back");
  if (redirectBack) {
    window.sessionStorage.removeItem("redirect-back");
    navigate(redirectBack);
  }
  return null;
};

const App = () => (
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <UserLoader>
        <Router>
          <RedirectFromSessionStorage />

          <NotFound>
            <Redirect to="/" />
          </NotFound>

          <Route path="/effort/:userId?">
            <EffortModel />
          </Route>

          <Route exact path="/">
            <ActivityList />
          </Route>

          <Route path="/users">
            <UserList />
          </Route>

          <Route path="/user/:userId">
            <ActivityList />
          </Route>

          <Route path="/activity/:stravaId">
            <StravaLoader>
              <Analysis />
            </StravaLoader>
          </Route>
        </Router>
      </UserLoader>
    </Suspense>
  </React.StrictMode>
);

export default App;
